import { NotificationCategory } from "../enums/notification-category.enum";
import { BaseDto } from "./base.dto";

export class NotificationDto extends BaseDto {
    title: string;
    content: NotifContent;
    data: NotifData;
    seen: [];
    receiverId: string;
    receiverType: string;
    category: NotificationCategory;

    avatar?:string;
    reporter?:string;
    color?:string;
}

export class NotifData {
    itemId: string;
    sheetId: number;
    spaceId: number;
    viewId?:number;
}

export class NotifContent {
    reporterName: string;
}
