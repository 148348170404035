<div *ngIf="!initializing" style="display:flex; height: 100vh; overflow: hidden;">

  <div class="navigation-container">
    <app-navigation></app-navigation>
  </div>

  <div style="height: 100vh; flex-grow: 1; overflow: hidden auto">
    <router-outlet></router-outlet>
  </div>
</div>

<atw-upload-toast
  style="display:block;position: fixed;bottom: 10px;right:10px;"/>

<div *ngIf="initializing">
  <app-loading/>

</div>
